import React, {useEffect, useState, useContext} from "react";
import { AuthContext } from '../context/Auth';
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { getWebConfig } from "../services/apiServices";
const functions = require("../util/functions");

// const zodiacSigns = ["Aries","Taurus","Gemini","Cancer","Leo","Virgo","Libra","Scorpius","Sagittarius","Capricorn","Aquarius","Pisces"];
const sorters = [
  {title: "Relevance", value: "relevance", sortOrder: "asc"}, 
  {title: "Experience (High to Low)", value: "experience", sortOrder: "desc"},
  {title: "Price (High to Low)", value: "price", sortOrder: "desc"},
  {title: "Price (Low to High)", value: "price", sortOrder: "asc"},
  {title: "Name (A-Z)", value: "name", sortOrder: "asc"},
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SortDD(props) {
  const { user} = useContext(AuthContext) || {user:{}};
  const [sort, setSort] = useState(sorters)
  const [host, sethost] = useState("");

  useEffect(() => {
    sethost(window.location.host);
  }, []);

  useEffect(() => {

    const callConfig = async () => {
      try {
        let configDataResponse;
        const specialHosts = [
          'localhost:8000',
          process.env.GATSBY_JIO_BASE,
          process.env.GATSBY_MEESHO_BASE,
          process.env.GATSBY_NAMAH_BASE,
          process.env.GATSBY_LOKAL_BASE,
          process.env.GATSBY_BODHI_BASE,

        ];

        if (specialHosts.includes(host)) {
          configDataResponse = await getWebConfig(host);

          // configDataResponse = await getWebConfig(host === 'localhost:8000' || 'bodhi.web' ? 'web' : host );
        } else {
          configDataResponse = await getWebConfig(host);

          // configDataResponse = await getWebConfig(host === 'localhost:8000' || 'bodhi.web' ? 'web' : host);
        }

        if (configDataResponse) {
          if(configDataResponse?.payLoad?.flatPriceOfferCampaign){
            let s = sorters.filter((item)=> item.value != 'price')
            setSort(s)
          }
        }
      } catch (error) {
        console.error("Error fetching config data:", error);
      }
    };
    if (user && host) {
      callConfig();
    }
    // callConfig();
  }, [user, host]);


  return (
    <Menu as="div" className="relative z-[5] mr-1 md:mr-4">
      <div className="">
        <Menu.Button className="text-gray-800 inline-flex justify-center items-center rounded-md px-1 md:px-4 py-2 bg-transparent text-sm font-medium focus:outline-none md:text-base font-medium worksans">
          <span className="text-black text-xs md:text-sm tracking-tight md:tracking-normal">Sort By: <h6 className="inline text-orange-500">{functions.capitalize(props.sort.title)}</h6> </span>
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition-all ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-2 px-2 flex flex-col items-start">
              {sort.map((item, i)=>{
                  return (
                    <Menu.Item key={i}>
                    {({ active }) => (
                      <a
                        
                        className={classNames(
                          active ? "hover:text-[#ff4500]" : "",
                          "m-1 text-xs text-gray-800 cursor-pointer"
                        )}
                        onClick={() => {let s = {
                          title:item.title,
                          sortBy: item.value,
                          sortOrder: item.sortOrder
                        }
                        props.setsort({...s})
                      }}
                      >
                        {item.title}
                      </a>
                    )}
                  </Menu.Item>
                  )
              })}
            
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
